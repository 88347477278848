import { Switch, Route } from "react-router-dom";
import * as Layouts from "./../app/layouts";
import * as Containers from "./../app/containers";

function Router() {
  return (
    <Switch>
      <Layouts.NonPrivateRoute
        exact
        path="/verify"
        component={Containers.VerifyPage}
      />
      <Layouts.NonPrivateRoute exact path="/" component={Containers.Login} />
      <Layouts.NonPrivateRoute
        exact
        path="/forgetPassword"
        component={Containers.forgetPassword}
      />
      <Layouts.NonPrivateRoute
        exact
        path="/resetPassword/:id"
        component={Containers.resetPassword}
      />
      <Layouts.NonPrivateRoute
        exact
        path="/walletLogin"
        component={Containers.walletLogin}
      />
      <Layouts.NonPrivateRoute
        exact
        path="/twoAuthenticationModal"
        component={Containers.TwoAuthentication}
      />
      <Layouts.NonPrivateRoute
        exact
        path="/authenticate"
        component={Containers.GoogleAuthenticode}
      />
      <Layouts.NonPrivateRoute
        exact
        path="/onboarding"
        component={Containers.ChooseRoles}
      />
      <Layouts.NonPrivateRoute
        exact
        path="/sign-up"
        component={Containers.Signup}
      />
      <Layouts.NonPrivateRoute
        exact
        path="/chooseProtection"
        component={Containers.ChooseProtection}
      />
      <Layouts.NonPrivateRoute
        exact
        path="/connect-bank-account"
        component={Containers.ConnectBankAccount}
      />

      <Layouts.NonPrivateRoute
        exact
        path="/verify-Phone"
        component={Containers.Verify}
      />
      <Layouts.NonPrivateRoute
        exact
        path="/verifyOtp"
        component={Containers.VerifyOtp}
      />
      <Layouts.NonPrivateRoute
        exact
        path="/verifyEmail"
        component={Containers.VerifyEmail}
      />
      <Layouts.NonPrivateRoute
        exact
        path="/verifyemailOtp"
        component={Containers.VerifyemailOtp}
      />
      {/* <Layouts.NonPrivateRoute exact path="/authenticate" component={Containers.GoogleAuthenticode} /> */}
      {/* <Layouts.NonPrivateRoute exact path="/authenticate" component={Containers.GoogleAuthenticode} /> */}
      {/* <Layouts.NonPrivateRoute exact path="/authenticate" component={Containers.GoogleAuthenticode} /> */}
      {/* <Layouts.PrivateRoute exact path="/wallet" component={Containers.Wallet} /> */}
      <Layouts.PrivateRoute
        exact
        path="/notifications"
        component={Containers.NotificationList}
      />
      <Layouts.PrivateRoute
        exact
        path="/googleAnalytics"
        component={Containers.Analyticsss}
      />
      <Layouts.PrivateRoute
        exact
        path="/editProfile"
        component={Containers.EditProfile}
      />
      <Layouts.PrivateRoute
        exact
        path="/subscriber"
        component={Containers.Subscriber}
      />
      <Layouts.PrivateRoute
        exact
        path="/myProfile"
        component={Containers.MyProfile}
      />
      <Layouts.PrivateRoute
        exact
        path="/plans"
        component={Containers.ScalePlan}
      />
      <Layouts.PrivateRoute
        exact
        path="/plans-selected"
        component={Containers.PlanSelected}
      />
      <Layouts.PrivateRoute
        exact
        path="/wallet"
        component={Containers.WalletStats}
      />
      <Layouts.PrivateRoute
        exact
        path="/create-wallet"
        component={Containers.Wallet}
      />
      <Layouts.PrivateRoute
        exact
        path="/bank-details"
        component={Containers.BankDetails}
      />
      <Layouts.PrivateRoute
        exact
        path="/payout-details"
        component={Containers.PayoutDetails}
      />
      <Layouts.PrivateRoute
        exact
        path="/request-kyc"
        component={Containers.RequestKYC}
      />
      <Layouts.PrivateRoute
        exact
        path="/IDVerification"
        component={Containers.IDVerification}
      />
      <Layouts.PrivateRoute
        exact
        path="/addBankAccount"
        component={Containers.AddBankAccount}
      />
      <Layouts.PrivateRoute
        exact
        path="/businessRegistration"
        component={Containers.BusinessRegistration}
      />
      <Layouts.PrivateRoute
        exact
        path="/createRole"
        component={Containers.CreateRole}
      />
      <Layouts.PrivateRoute
        exact
        path="/requestKYB"
        component={Containers.RequestKYB}
      />
      <Layouts.PrivateRoute
        exact
        path="/business-id-verification"
        component={Containers.BusinessIdVerification}
      />
      <Layouts.PrivateRoute
        exact
        path="/payments"
        component={Containers.Payments}
      />
      <Layouts.PrivateRoute
        exact
        path="/setup-address"
        component={Containers.SetupAddress}
      />
      <Layouts.PrivateRoute
        exact
        path="/pickupAddress/:addressId"
        component={Containers.PickupAddress}
      />
      <Layouts.PrivateRoute
        exact
        path="/dropOff-location/:addressId"
        component={Containers.DropOffLocation}
      />
      <Layouts.PrivateRoute
        exact
        path="/shipping-location/:addressId"
        component={Containers.ShippingLocation}
      />
      <Layouts.PrivateRoute
        exact
        path="/local-pickup/:addressId"
        component={Containers.LocalPickup}
      />
      <Layouts.PrivateRoute
        exact
        path="/store-location/:addressId"
        component={Containers.StoreAddress}
      />
      {/* Start Message Routes */}
      <Layouts.PrivateRoute
        exact
        path="/directMessage"
        component={Containers.DirectMessages}
      />
      <Layouts.PrivateRoute
        exact
        path="/chatNew"
        component={Containers.ChatNew}
      />
      <Layouts.PrivateRoute
        exact
        path="/broadCastMessage"
        component={Containers.BroadcastMessage}
      />
      <Layouts.PrivateRoute
        exact
        path="/inquaries"
        component={Containers.Inquaries}
      />
      <Layouts.PrivateRoute
        exact
        path="/rfqMessage"
        component={Containers.RfqMessage}
      />
      {/* End Message Routes */}
      {/* Start ProductPage Routes */}
      <Layouts.ProductPrivateRoute
        exact
        path="/products"
        component={Containers.Product}
      />
      <Layouts.ProductPrivateRoute
        exact
        path="/add-product/:flag"
        component={Containers.AddProduct}
      />
      <Layouts.ProductPrivateRoute
        exact
        path="/editProducts"
        component={Containers.EditProduct}
      />
      <Layouts.ProductPrivateRoute
        exact
        path="/editProductSupply"
        component={Containers.EditProductSupply}
      />
      {/* <Layouts.ProductPrivateRoute exact path="/createProductSupply" component={Containers.CreatePhyicalProduct} /> */}
      <Layouts.ProductPrivateRoute
        exact
        path="/productUploadFile"
        component={Containers.ProductUploadFile}
      />
      <Layouts.ProductPrivateRoute
        exact
        path="/productPlugin"
        component={Containers.ProductPlugin}
      />
      <Layouts.ProductPrivateRoute
        exact
        path="/productPreview"
        component={Containers.ProductUploadPreview}
      />

      {/* End ProductPage Routes */}

      {/* Start My ProductPage Routes */}
      <Layouts.PrivateRoute
        exact
        path="/myProducts"
        component={Containers.MyProducts}
      />
      <Layouts.PrivateRoute
        exact
        path="/productList"
        component={Containers.ProductList}
      />
      <Layouts.PrivateRoute
        exact
        path="/productOffer"
        component={Containers.ProductOffer}
      />
      <Layouts.PrivateRoute
        exact
        path="/categoryListview/:flag/:serviceType"
        component={Containers.CategoryListview}
      />
      <Layouts.PrivateRoute
        exact
        path="/categoryDetail/:flag/:type/:id"
        component={Containers.CategoryDetail}
      />
      <Layouts.PrivateRoute
        exact
        path="/product-subcategory"
        component={Containers.CategoryListview}
      />
      <Layouts.PrivateRoute
        exact
        path="/product-brands"
        component={Containers.CategoryListview}
      />
      {/* End My ProductPage Routes */}
      {/* Start Service Routes */}
      <Layouts.ProductPrivateRoute
        exact
        path="/add-service"
        component={Containers.AddService}
      />
      <Layouts.ProductPrivateRoute
        exact
        path="/editService"
        component={Containers.EditService}
      />
      <Layouts.ProductPrivateRoute
        exact
        path="/editServiceSupply"
        component={Containers.EditServiceSupply}
      />
      {/* End Service Routes */}
      {/* Start MyService Page Routes */}
      <Layouts.PrivateRoute
        exact
        path="/myService"
        component={Containers.MyService}
      />
      <Layouts.PrivateRoute
        exact
        path="/service-list"
        component={Containers.ServiceList}
      />
      <Layouts.PrivateRoute
        exact
        path="/serviceOffer"
        component={Containers.ProductOffer}
      />
      <Layouts.PrivateRoute
        exact
        path="/categoryListview/:flag/:serviceType"
        component={Containers.CategoryListview}
      />
      <Layouts.PrivateRoute
        exact
        path="/categoryDetail/:flag/:type/:id"
        component={Containers.CategoryDetail}
      />
      <Layouts.PrivateRoute
        exact
        path="/service-category"
        component={Containers.CategoryListview}
      />
      <Layouts.PrivateRoute
        exact
        path="/service-subcategory"
        component={Containers.CategoryListview}
      />
      {/* End MyService Page Routes */}
      {/* Start MyOrders Routes */}
      <Layouts.PrivateRoute
        exact
        path="/myOrders"
        component={Containers.MyOrders}
      />
      <Layouts.PrivateRoute
        exact
        path="/productRefund"
        component={Containers.OrderReturn}
      />
      <Layouts.PrivateRoute
        exact
        path="/productrefunds"
        component={Containers.Productrefunds}
      />

      <Layouts.PrivateRoute
        exact
        path="/allOrder"
        component={Containers.AllOrder}
      />
      <Layouts.PrivateRoute
        exact
        path="/orderReview/:id"
        component={Containers.OrderReview}
      />
      <Layouts.PrivateRoute
        exact
        path="/ordersView"
        component={Containers.OrdersView}
      />
      <Layouts.PrivateRoute
        exact
        path="/orderTrack/:id"
        component={Containers.OrderTrack}
      />
      <Layouts.PrivateRoute
        exact
        path="/deliveryStatus/:id"
        component={Containers.DeliveryStatus}
      />

      <Layouts.PrivateRoute
        exact
        path="/processingOrders"
        component={Containers.ProcessingOrders}
      />
      <Layouts.PrivateRoute
        exact
        path="/completeOrders"
        component={Containers.CompleteOrders}
      />
      <Layouts.PrivateRoute
        exact
        path="/cancelOrders"
        component={Containers.CancelOrders}
      />
      <Layouts.PrivateRoute
        exact
        path="/refundOrders"
        component={Containers.RefundOrders}
      />
      <Layouts.ProductPrivateRoute
        exact
        path="/refundorderInvoice/:id"
        component={Containers.RefundorderInvoice}
      />
      <Layouts.ProductPrivateRoute
        exact
        path="/refundProcessing"
        component={Containers.RefundProcessing}
      />
      <Layouts.PrivateRoute
        exact
        path="/ConfirmationSuccess"
        component={Containers.ConfirmationSuccess}
      />

      {/* End MyOrders Routes */}
      {/* Start Inventory Routes */}
      <Layouts.PrivateRoute
        exact
        path="/inventory"
        component={Containers.Inventory}
      />
      <Layouts.PrivateRoute
        exact
        path="/inventoryList"
        component={Containers.InventoryList}
      />
      <Layouts.PrivateRoute
        exact
        path="/inventoryviewDetail/:id/:flag"
        component={Containers.InventoryviewDetail}
      />
      <Layouts.PrivateRoute
        exact
        path="/lowstockInventory"
        component={Containers.LowstockInventory}
      />

      {/* End Inventory Routes */}
      <Layouts.PrivateRoute
        exact
        path="/tracking"
        component={Containers.Tracking}
      />
      <Layouts.ProductPrivateRoute
        exact
        path="/trackingorder"
        component={Containers.TrackingOrder}
      />

      {/* Start Wallet Routes */}
      <Layouts.PrivateRoute
        exact
        path="/walletMain"
        component={Containers.WalletMain}
      />
      <Layouts.PrivateRoute
        exact
        path="/transections"
        component={Containers.Transections}
      />
      <Layouts.PrivateRoute
        exact
        path="/jbrwallet"
        component={Containers.JbrWallet}
      />
      <Layouts.PrivateRoute
        exact
        path="/localcurrencywallet"
        component={Containers.LocalCurrencyWallet}
      />
      <Layouts.PrivateRoute
        exact
        path="/withdrawal"
        component={Containers.WithDrawal}
      />
      <Layouts.PrivateRoute
        exact
        path="/instantbankTransfer"
        component={Containers.InstantbankTransfer}
      />

      <Layouts.PrivateRoute
        exact
        path="/walletPayment"
        component={Containers.WalletPayment}
      />
      <Layouts.PrivateRoute
        exact
        path="/walletRefund"
        component={Containers.WalletRefund}
      />
      <Layouts.PrivateRoute
        exact
        path="/walletrefundInvoice"
        component={Containers.WalletrefundInvoice}
      />
      <Layouts.PrivateRoute
        exact
        path="/walletDispute"
        component={Containers.WalletDispute}
      />
      {/* Start Wallet Routes */}
      {/* new */}
      <Layouts.PrivateRoute
        exact
        path="/coupon"
        component={Containers.Coupons}
      />
      {/* Start My Appointments Routes */}

      <Layouts.PrivateRoute
        exact
        path="/myAppointments"
        component={Containers.MyAppointments}
      />
      <Layouts.PrivateRoute
        exact
        path="/todayAppointments"
        component={Containers.TodayAppointments}
      />
      <Layouts.PrivateRoute
        exact
        path="/upcomingDetails"
        component={Containers.UpcomingDetails}
      />
      <Layouts.PrivateRoute
        exact
        path="/appointments/:flag/:status"
        component={Containers.FulfilledAppointment}
      />
      {/* <Layouts.PrivateRoute exact path="/cancelledAppointment" component={Containers.CancelledAppointment} /> */}

      <Layouts.PrivateRoute
        exact
        path="/fulfilledAppointment"
        component={Containers.FulfilledAppointment}
      />
      <Layouts.PrivateRoute
        exact
        path="/cancelledAppointment"
        component={Containers.CancelledAppointment}
      />
      {/* End My Appointments Routes */}

      {/* Start My POS Routes */}
      <Layouts.PrivateRoute exact path="/myPos" component={Containers.MyPos} />
      <Layouts.PrivateRoute
        exact
        path="/posSales"
        component={Containers.PosSales}
      />
      <Layouts.PrivateRoute
        exact
        path="/createUser"
        component={Containers.CreateUser}
      />
      <Layouts.PrivateRoute
        exact
        path="/posUser"
        component={Containers.PosUser}
      />
      <Layouts.PrivateRoute
        exact
        path="/staffPayment"
        component={Containers.StaffPayment}
      />

      {/* End My POS Routes */}
      {/* Start My Purchase Routes */}
      <Layouts.PrivateRoute
        exact
        path="/myPurchases"
        component={Containers.MyPurchases}
      />
      <Layouts.PrivateRoute
        exact
        path="/newPurchase"
        component={Containers.NewPurchase}
      />
      <Layouts.ProductPrivateRoute
        exact
        path="/purchaseReport"
        component={Containers.PurchaseReport}
      />

      <Layouts.ProductPrivateRoute
        exact
        path="/newPurchaseInvoice"
        component={Containers.NewpurchaseInvoice}
      />
      <Layouts.PrivateRoute
        exact
        path="/processingPurchase"
        component={Containers.ProcessingPurchase}
      />
      {/* <Layouts.ProductPrivateRoute exact path="/processingpurchaseInvoice" component={Containers.ProcessingpurchaseInvoice} /> */}
      <Layouts.PrivateRoute
        exact
        path="/cancelPurchase"
        component={Containers.CancelPurchase}
      />
      <Layouts.ProductPrivateRoute
        exact
        path="/cancelpurchaseInvoice"
        component={Containers.CancelpurchaseInvoice}
      />
      <Layouts.PrivateRoute
        exact
        path="/purchaseRefund"
        component={Containers.PurchaseRefund}
      />
      <Layouts.ProductPrivateRoute
        exact
        path="/purchaserefundProcess"
        component={Containers.PurchaserefundProcess}
      />
      <Layouts.PrivateRoute
        exact
        path="/receivedPurchase"
        component={Containers.ReceivedPurchase}
      />
      <Layouts.ProductPrivateRoute
        exact
        path="/receiveviewInvoice"
        component={Containers.ReceiveviewInvoice}
      />
      <Layouts.ProductPrivateRoute
        exact
        path="/receivedreportInvoice/:id"
        component={Containers.ReceivedreportInvoice}
      />
      {/* End My Purchase Routes */}

      {/* Star CampaignPage Routes */}
      <Layouts.PrivateRoute
        exact
        path="/campaign"
        component={Containers.Campaign}
      />
      <Layouts.PrivateRoute
        exact
        path="/promotional-activites"
        component={Containers.PromotionalActivities}
      />
      <Layouts.PrivateRoute
        exact
        path="/connected-account"
        component={Containers.ConnectedAccount}
      />
      <Layouts.PrivateRoute
        exact
        path="/template"
        component={Containers.Template}
      />
      {/* End CampaignPage Routes */}

      {/* Star Analytics Routes */}
      <Layouts.PrivateRoute
        exact
        path="/analytics"
        component={Containers.Analytics}
      />
      <Layouts.PrivateRoute
        exact
        path="/Gross-Profits"
        component={Containers.GrossProfits}
      />
      <Layouts.PrivateRoute
        exact
        path="/Total-Revenue"
        component={Containers.TotalRevenue}
      />
      <Layouts.PrivateRoute
        exact
        path="/Total-Costs"
        component={Containers.TotalCosts}
      />
      <Layouts.PrivateRoute
        exact
        path="/Total-POS-Orders"
        component={Containers.TotalPosOrders}
      />
      <Layouts.PrivateRoute
        exact
        path="/Total-Delivery-Orders"
        component={Containers.TotalDeliveryOrders}
      />
      <Layouts.PrivateRoute
        exact
        path="/Total-Shipping-Orders"
        component={Containers.TotalShippingOrders}
      />
      <Layouts.PrivateRoute
        exact
        path="/Total-Orders"
        component={Containers.TotalOrders}
      />
      <Layouts.PrivateRoute
        exact
        path="/Total-Inventory"
        component={Containers.TotalInventory}
      />
      <Layouts.PrivateRoute
        exact
        path="/Total-Product-Sold"
        component={Containers.TotalProductSold}
      />
      <Layouts.ProductPrivateRoute
        exact
        path="/salesReport/:flag"
        component={Containers.SalesReport}
      />
      {/* End Analytics Routes */}

      {/* Star Catalog Routes */}
      <Layouts.PrivateRoute
        exact
        path="/catalogs"
        component={Containers.Catalogs}
      />
      <Layouts.PrivateRoute
        exact
        path="/folderOpen/:id"
        component={Containers.FolderOpen}
      />
      {/* <Layouts.PrivateRoute exact path="/receivedCatalog" component={Containers.ReceivedCatalog}/> */}
      <Layouts.PrivateRoute
        exact
        path="/receivedFolder/:id"
        component={Containers.ReceivedFolderOpen}
      />

      {/* End Catalog Routes */}

      {/* Star Supports Routes */}
      <Layouts.PrivateRoute
        exact
        path="/support"
        component={Containers.Support}
      />
      <Layouts.PrivateRoute
        exact
        path="/agents"
        component={Containers.SupportAgents}
      />
      <Layouts.PrivateRoute
        exact
        path="/supportTicket"
        component={Containers.SupportTicket}
      />
      {/* <Layouts.PrivateRoute exact path="/pendingTicket" component={Containers.PendingTicket}/> */}
      <Layouts.PrivateRoute
        exact
        path="/supportticketDetail/:id"
        component={Containers.SupportticketDetail}
      />
      {/* <Layouts.PrivateRoute exact path="/pendingTicketsassign" component={Containers.PendingTicketsassign}/> */}
      {/* <Layouts.PrivateRoute exact path="/pendingassignDetail" component={Containers.PendingassignDetail}/> */}
      {/* <Layouts.PrivateRoute exact path="/resolveTicket" component={Containers.ResolveTicket}/> */}
      <Layouts.PrivateRoute
        exact
        path="/resolveticketDetail"
        component={Containers.ResolveticketDetail}
      />
      <Layouts.PrivateRoute
        exact
        path="/supportCall"
        component={Containers.SupportCall}
      />
      <Layouts.PrivateRoute
        exact
        path="/droppedCall"
        component={Containers.DroppedCall}
      />
      <Layouts.PrivateRoute
        exact
        path="/resolveCall"
        component={Containers.ResolveCall}
      />
      <Layouts.PrivateRoute
        exact
        path="/resolvecallDetail"
        component={Containers.ResolvecallDetail}
      />
      <Layouts.PrivateRoute
        exact
        path="/supportChat"
        component={Containers.SupportChat}
      />
      <Layouts.PrivateRoute
        exact
        path="/pendingChats"
        component={Containers.PendingChats}
      />
      <Layouts.PrivateRoute
        exact
        path="/pendingChatDetail"
        component={Containers.PendingChatDetail}
      />
      <Layouts.PrivateRoute
        exact
        path="/pendingchatAssign"
        component={Containers.PendingchatAssign}
      />
      <Layouts.PrivateRoute
        exact
        path="/chatassignDetail"
        component={Containers.ChatassignDetail}
      />
      <Layouts.PrivateRoute
        exact
        path="/resolveChats"
        component={Containers.ResolveChats}
      />
      <Layouts.PrivateRoute
        exact
        path="/resolvechatDetail"
        component={Containers.ResolvechatDetail}
      />
      <Layouts.PrivateRoute
        exact
        path="/supportEmail"
        component={Containers.SupportEmail}
      />
      <Layouts.PrivateRoute
        exact
        path="/pendingEmail"
        component={Containers.PendingEmail}
      />
      <Layouts.PrivateRoute
        exact
        path="/assignedEmail"
        component={Containers.AssignedEmail}
      />
      <Layouts.PrivateRoute
        exact
        path="/resolveEmail"
        component={Containers.ResolveEmail}
      />
      <Layouts.PrivateRoute
        exact
        path="/pendingemailDetail"
        component={Containers.PendingemailDetail}
      />
      <Layouts.PrivateRoute
        exact
        path="/assignemailDetail"
        component={Containers.AssignemailDetail}
      />
      <Layouts.PrivateRoute
        exact
        path="/resolveemailDetail"
        component={Containers.ResolveemailDetail}
      />
      <Layouts.PrivateRoute
        exact
        path="/newTicket"
        component={Containers.NewTicket}
      />
      <Layouts.PrivateRoute
        exact
        path="/newEmail"
        component={Containers.NewEmail}
      />

      <Layouts.PrivateRoute
        exact
        path="/Product/RefundsConfirmation"
        component={Containers.RefundConfirmation}
      />

      {/* End Supports Routes */}

      {/* Start Device*/}

      {/* Start Device*/}
      <Layouts.PrivateRoute
        exact
        path="/device"
        component={Containers.device}
      />

      {/* End Device*/}

      <Layouts.PrivateRoute
        exact
        path="/businesseditDetails"
        component={Containers.BussinesseditDeatils}
      />
      {/* <Layouts.PrivateRoute exact path="/businessDetails" component={Containers.BusinessDetails}/> */}
      <Layouts.PrivateRoute
        exact
        path="/pluginIntegrations"
        component={Containers.PluginIntegrations}
      />
      <Layouts.PrivateRoute
        exact
        path="/planSubscription"
        component={Containers.planSubscription}
      />
      <Layouts.PrivateRoute
        exact
        path="/systemEmployee"
        component={Containers.SystemEmployee}
      />
      <Layouts.PrivateRoute
        exact
        path="/systememployeHistory"
        component={Containers.SystememployeHistory}
      />
      <Layouts.PrivateRoute
        exact
        path="/staffRole"
        component={Containers.StaffRole}
      />
      <Layouts.PrivateRoute
        exact
        path="/userViewList"
        component={Containers.UserViewList}
      />
      <Layouts.PrivateRoute
        exact
        path="/viewLogHistory"
        component={Containers.ViewLogHistory}
      />
      <Layouts.PrivateRoute
        exact
        path="/viewRolePermission"
        component={Containers.ViewRolePermission}
      />
      <Layouts.PrivateRoute
        exact
        path="/rolePermissionEdit"
        component={Containers.RolePermissionEdit}
      />
      {/* <Layouts.PrivateRoute exact path="/systemConfigPlan" component={Containers.SystemConfigPlan}/> */}
      <Layouts.PrivateRoute
        exact
        path="/walletConfig"
        component={Containers.Payments}
      />
      <Layouts.PrivateRoute
        exact
        path="/bussinessDetails"
        component={Containers.BussinessDetails}
      />
      <Layouts.PrivateRoute
        exact
        path="/shippingPickup"
        component={Containers.SetupAddress}
      />
      <Layouts.PrivateRoute exact path="/taxes" component={Containers.Taxes} />
      {/* <Layouts.PrivateRoute exact path="/systemTaxes" component={Containers.SystemTaxes} /> */}

      <Layouts.PrivateRoute
        exact
        path="/taxInformation"
        component={Containers.TaxInformation}
      />
      <Layouts.PrivateRoute exact path="/legal" component={Containers.Legal} />
      <Layouts.PrivateRoute
        exact
        path="/invoice"
        component={Containers.Invoice}
      />
      {/* <Layouts.PrivateRoute exact path="/pos" component={Containers.Pos} /> */}
      <Layouts.PrivateRoute
        exact
        path="/notification"
        component={Containers.Notification}
      />
      <Layouts.PrivateRoute
        exact
        path="/languages"
        component={Containers.Languages}
      />
      <Layouts.PrivateRoute
        exact
        path="/security"
        component={Containers.SecuritySetting}
      />
      <Layouts.PrivateRoute exact path="/faq" component={Containers.Faq} />
      <Layouts.PrivateRoute
        exact
        path="/policies"
        component={Containers.Policies}
      />
      <Layouts.PrivateRoute
        exact
        path="/quickbook"
        component={Containers.Quickbook}
      />
      <Layouts.PrivateRoute
        exact
        path="/locations"
        component={Containers.Locations}
      />
      <Layouts.PrivateRoute
        exact
        path="/retailOrder"
        component={Containers.RetailOrder}
      />
      <Layouts.PrivateRoute
        exact
        path="/systemService"
        component={Containers.SystemService}
      />
      <Layouts.PrivateRoute
        exact
        path="/personalDetail"
        component={Containers.PersonalDetailsSystem}
      />

      {/* End System Config*/}
      <Layouts.PrivateRoute
        exact
        path="/userList"
        component={Containers.UserList}
      />

      <Layouts.PrivateRoute
        exact
        path="/driverTracking/:id"
        component={Containers.DriverTracking}
      />
      <Layouts.PrivateRoute
        exact
        path="/driverProfile"
        component={Containers.DriverProfile}
      />
      <Layouts.PrivateRoute
        exact
        path="/driverTransaction"
        component={Containers.DriverTransaction}
      />
      <Layouts.PrivateRoute
        exact
        path="/driverDeliveries"
        component={Containers.DriverDeliveries}
      />
      <Layouts.PrivateRoute
        exact
        path="/user-list/:flag"
        component={Containers.Manufactures}
      />
      <Layouts.PrivateRoute
        exact
        path="/customer"
        component={Containers.Consumer}
      />
      <Layouts.PrivateRoute
        exact
        path="/consumerProfile"
        component={Containers.ConsumerProfile}
      />
      <Layouts.PrivateRoute
        exact
        path="/consumerOrder"
        component={Containers.ConsumerOrder}
      />
      <Layouts.PrivateRoute
        exact
        path="/userDetails/:flag/:id"
        component={Containers.ManufactureDetails}
      />
      <Layouts.PrivateRoute
        exact
        path="/trackingMap/:id"
        component={Containers.TrackingMap}
      />
      <Layouts.PrivateRoute
        exact
        path="/purchaseTracking"
        component={Containers.PurchaseTracking}
      />

      {/* Start Bookings Section */}
      <Layouts.PrivateRoute
        exact
        path="/bookings"
        component={Containers.Bookings}
      />
      <Layouts.PrivateRoute
        exact
        path="/completed-bookings"
        component={Containers.CompletedBooking}
      />
      <Layouts.PrivateRoute
        exact
        path="/upcoming-bookings"
        component={Containers.UpcomingBooking}
      />

      <Layouts.PrivateRoute
        exact
        path="/giftcard"
        component={Containers.GiftCards}
      />

      <Layouts.PrivateRoute
        exact
        path="/app-settings"
        component={Containers.AppSetting}
      />

      <Route component={Containers.NotFound} />
    </Switch>
  );
}

export default Router;
