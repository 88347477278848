import React, { useEffect, useState } from "react";
import {
  moneyBag,
  transactionIcon,
  walletCircle,
  walletCard,
} from "../../../utilities/images";
import ChartArea from "../dashboard/wallet/ChartArea";
import { getUserByIdApiAsync, selectLoginAuth } from "../auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  getCashFlowStatisticsApiAsync,
  getOrderWalletTransactionsAsync,
  getTransactionStatisticsApiAsync,
  getWalletBallanceApiAsync,
  selectMerchantStatus,
} from "../dashboard/dashboardSlice";
import DashboardNavbar from "../dashboard/DashboardNavbar";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";

const WalletMain = () => {
  const dispatch = useDispatch();
  const [checked, setChecked] = useState({
    JOBR: true,
    Card: true,
    Cash: true
  })
  const auth = useSelector(selectLoginAuth);
  const [loading, setLoading] = useState(false);
  const [walletBalance, setWalletBalance] = useState("");
  const merchantStatus = useSelector(selectMerchantStatus);
  const [cashFlowData, setCashFlowData] = useState("");
  const [transactionStatistics, setTransactionStatistics] = useState([]);
  const [orderWalletTransactions, setOrderWalletTransactions] = useState();
  const [filterValue, setFilterValue] = useState("week");
  const history = useHistory();

  const handleFilterValue = (value) => {
    setFilterValue(value);
  };


  function addThousandSeparator(number) {
    return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const options1 = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  let [dataSets, setDataSets] = useState([
    // {
    //   fill: true,
    //   label: "JOBR",
    //   data: orderWalletTransactions?.graphData?.datasets
    //     ? orderWalletTransactions.graphData.datasets[0]
    //     : "",
    //   borderColor: "#275AFF",
    //   backgroundColor: "#275AFF",
    // },
    {
      fill: true,
      label: "Cash",
      data: orderWalletTransactions?.graphData?.datasets
        ? orderWalletTransactions.graphData.datasets[1]
        : "",
      borderColor: "#FB466C",
      backgroundColor: "#6B84D3",
    },
    {
      fill: true,
      label: "Card",
      data: orderWalletTransactions?.graphData?.datasets
        ? orderWalletTransactions.graphData.datasets[2]
        : "",
      borderColor: "#FB466C",
      backgroundColor: "#102773",
    },
  ]);


  const serviceSalesData = {
    labels: orderWalletTransactions?.graphData?.labels,
    datasets: dataSets,
  };
  const getUserDataByIdHandle = () => {
    let data = {
      postData: {
        id: auth?.payload?.id
          ? auth?.payload?.id
          : auth?.payload?.user?.user_profiles?.id,
      },
      tokenData: auth?.payload?.token,
    };
    setLoading(true);
    dispatch(getUserByIdApiAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        if (merchantStatus?.payload?.wallet_status === true) {
          let data = {
            tokenData: auth?.payload?.token,
          };
          dispatch(getWalletBallanceApiAsync(data))
            .then(unwrapResult)
            .then((obj) => {
              setWalletBalance(obj?.payload?.sila_balance);
              setLoading(false);
            })
            .catch((obj) => {
              setLoading(false);
            });
        }
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  const cashFlowStatisticsHandle = () => {
    let data = {
      postData: {
        filter:
          filterValue === "This week" ? "week" : filterValue?.toLowerCase(),
      },
      tokenData: auth?.payload?.token,
    };
    setLoading(true);
    dispatch(getCashFlowStatisticsApiAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setCashFlowData(obj?.payload);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };


  const handelDataSetChange = (e, value, num, color) => {
    console.log(value,e.target.checked);
    if (e.target.checked) {
      setDataSets([
        ...dataSets,
        {
          fill: true,
          label: value,
          data: orderWalletTransactions?.graphData?.datasets
            ? orderWalletTransactions.graphData.datasets[num]
            : "",
          borderColor: color,
          backgroundColor: color,
        },
      ]);
      console.log(dataSets)
    } else {
      setDataSets(
        dataSets.filter((item) => item.label !== value))
    }
  };

  const getTransactionStatisticsApiAsyncHandle = () => {
    let data = {
      postData: {
        filter:
          filterValue === "This week" ? "week" : filterValue?.toLowerCase(),
      },
      tokenData: auth?.payload?.token,
    };
    setLoading(true);
    dispatch(getTransactionStatisticsApiAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setTransactionStatistics(obj?.payload);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const getOrderWalletTransactionsAsyncHandler = () => {
    let data = {
      postData: {
        filter:
          filterValue === "This week" ? "week" : filterValue?.toLowerCase(),
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
        // seller_id: "b169ed4d-be27-44eb-9a08-74f997bc6a2j",
      },
      tokenData: auth?.payload?.token,
    };
    setLoading(true);
    dispatch(getOrderWalletTransactionsAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setOrderWalletTransactions(obj?.payload);
        setDataSets([
          // {
          //   fill: true,
          //   label: "JOBR",
          //   data: obj?.payload?.graphData?.datasets
          //     ? obj?.payload.graphData.datasets[0]
          //     : "",
          //   borderColor: "#275AFF",
          //   backgroundColor: "#275AFF",
          // },
          {
            fill: true,
            label: "Cash",
            data: obj?.payload?.graphData?.datasets
              ? obj?.payload.graphData.datasets[1]
              : "",
            borderColor: "#FB466C",
            backgroundColor: "#6B84D3",
          },
          {
            fill: true,
            label: "Card",
            data: obj?.payload?.graphData?.datasets
              ? obj?.payload.graphData.datasets[2]
              : "",
            borderColor: "#FB466C",
            backgroundColor: "#102773",
          },
        ])
        setChecked({
          JOBR: true,
          Card: true,
          Cash: true
        })
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });

  };

  useEffect(() => {
    getUserDataByIdHandle();
    cashFlowStatisticsHandle();
    getTransactionStatisticsApiAsyncHandle();
    getOrderWalletTransactionsAsyncHandler();
    
  }, [filterValue]);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Wallet";
  }, []);

  return (
    <>
      <DashboardNavbar title="Wallet Overview" backShow={false} />
      <div className="walletMain">
        {/* <div className='topHeader_'>
        <div className="topStickyHeader_">
          <div className="row align-items-center">
            <div className="col-md-6">
              <h2 className="mainHeading">
                <span>Wallet</span>
              </h2>
            </div>

          </div>
        </div>
      </div> */}
        {/* <div className='row mb-3'>
        <div className='col-md-12 text-end'>
          <div className="dropdown weekDropdown">
            <button className="dropdown-toggle cateSelectd" type="button" data-bs-toggle="dropdown" aria-expanded="false">
              {filterValue} <img src={downlight} alt="img" />
            </button>
            <ul className="dropdown-menu weekDropdownMenus">
              <li><a className="dropdown-item" onClick={() => handleFilterValue("Today")} >Today</a></li>
              <li><a className="dropdown-item" onClick={() => handleFilterValue("This week")} >This week</a></li>
              <li><a className="dropdown-item" onClick={() => handleFilterValue("Month")}> Month</a></li>
              <li><a className="dropdown-item" onClick={() => handleFilterValue("Year")}> Year</a></li>
            </ul>
          </div>
        </div>
      </div> */}
        <div className="row mt-4">
          <div className="col-lg-12 d-flex justify-content-end">
            <ul className="weekNav">
              <li onClick={() => setFilterValue("today")}>
                <Link
                  className={`weekMenu ${
                    filterValue == "today" ? "active" : ""
                  }`}
                  to="#"
                >
                  Today
                </Link>
              </li>
              <li onClick={() => setFilterValue("week")}>
                <Link
                  className={`weekMenu ${
                    filterValue == "week" ? "active" : ""
                  }`}
                  to="#"
                >
                  Weekly
                </Link>
              </li>
              <li onClick={() => setFilterValue("month")}>
                <Link
                  className={`weekMenu ${
                    filterValue == "month" ? "active" : ""
                  }`}
                  to="#"
                >
                  Monthly
                </Link>
              </li>
              <li onClick={() => setFilterValue("year")}>
                <Link
                  className={`weekMenu ${
                    filterValue == "year" ? "active" : ""
                  }`}
                  to="#"
                >
                  Yearly
                </Link>
              </li>
            </ul>
          </div>
        </div>
        {loading === true ? (
          <span className="spinner-border inner-spin spinner-border-sm"></span>
        ) : (
          <>
            {/* <div className="availableBlnc">
                <div className='row'>
                  <div className='col-md-4 col-lg-4 col-xl-4 col-xxl-6 '>
                    <div className='leftAvailblnc'>
                      <h5 className='validText mb-3'>Available Balance</h5>
                      <h4 className='blacktextHead'>${merchantStatus?.payload?.wallet_status === true ? walletBalance : 0}</h4>
                    </div>

                  </div>
                  <div className='col-md-8 col-lg-8 col-xl-8 col-xxl-6'>
                    <div className='leftAvailblnc'>
                      <div className='row'>
                        {
                          transactionStatistics?.length > 0 ?
                            transactionStatistics?.map((v, i) => {
                              return (
                                <div key={i} className='col-md-6 col-lg-6 customSpce mb-2'>
                                  <div className='percentageContainer justify-content-between align-items-center'>
                                    <div className='percentageValues'>
                                      <h3 className='mainHeadingsub_ '>{v?.count?.toFixed(2)}</h3>
                                      <p className='textinner_'>{v?.name}</p>
                                    </div>
                                    <div className={`percentageRating ${v?.name === "Total USD Out" ? 'redRating' : ""} mapbg`}>
                                      <img src={v?.name === "Total USD Out" ? redMapimg : shareMap} className="" alt='ShareMap' />
                                      <p className='percentageRate'>{v?.percentage}%</p>
                                    </div>
                                  </div>
                                </div>
                              )
                            }) : <></>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            <div className="walletOverviewData ">
              <div className="row">
                <div
                  className="col-xl-3 col-lg-6 col-md-6  pe-2 mt-3"
                  onClick={() => {
                    history.push(`/transections?transType=all`);
                  }}
                >
                  <div className="grossTotalorder">
                    <img
                      src={transactionIcon}
                      className="img-fluid"
                      alt="transactionIcon"
                    />
                    <p className="groSub mt-4 mb-0">Total</p>
                    <h4 className="groMain">
                      {addThousandSeparator(orderWalletTransactions?.data?.total?.toFixed(2))}
                    </h4>
                  </div>
                </div>
                <div
                  className="col-xl-3 col-lg-6 col-md-6  ps-2 pe-2 mt-3"
                  onClick={() => {
                    history.push(`/transections?transType=cash`);
                  }}
                >
                  <div className="grossTotalorder">
                    <img
                      src={moneyBag}
                      className="img-fluid"
                      alt="moneyBagImage"
                    />
                    <p className="groSub mt-4 mb-0">Cash</p>
                    <h4 className="groMain">
                      ${addThousandSeparator(orderWalletTransactions?.data?.cash?.toFixed(2))}
                    </h4>
                  </div>
                </div>
                {/* <div
                  className="col-xl-3 col-lg-6 col-md-6 ps-2 pe-2 mt-3"
                  onClick={() => {
                    history.push(`/transections?transType=jbr`);
                  }}
                >
                  <div className="grossTotalorder">
                    <img
                      src={walletCircle}
                      className="img-fluid"
                      alt="walletCircleImage"
                    />
                    <p className="groSub mt-4 mb-0">JOBR</p>
                    <h4 className="groMain">
                      {addThousandSeparator(orderWalletTransactions?.data?.jbr?.toFixed(2))}
                    </h4>
                  </div>
                </div> */}
                <div
                  className="col-xl-3 col-lg-6 col-md-6 ps-2 mt-3"
                  onClick={() => {
                    history.push(`/transections?transType=card`);
                  }}
                >
                  <div className="grossTotalorder">
                    <img
                      src={walletCard}
                      className="img-fluid"
                      alt="walletcardImg"
                    />
                    <p className="groSub mt-4 mb-0">Card</p>
                    <h4 className="groMain">
                      ${addThousandSeparator(orderWalletTransactions?.data?.card?.toFixed(2))}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="tradesValue mt-4 mb-4">
              <div className="row">
                <div className="col-md-12">
                  <div className="chartsOuter">
                    <h3 className="placementText mt-3">Transactions</h3>
                    <div className="graphChecked_ mt-3 mb-2 ">
                      <div className="graphBox_ me-3">
                        <input
                          type="checkbox"
                          id="custom-checkbox-selectAll2"
                          className="checkBox "
                          name="custom-checkbox-selectAll"
                          value="custom-checkbox-selectAll"
                          onChange={(e) => {
                            handelDataSetChange(e, "JOBR", 0, "#275AFF");
                            setChecked({...checked,JOBR: checked.JOBR===true ? false: true})
                          }}
                          checked={checked.JOBR}
                        />
                        {/* <label
                          htmlFor="custom-checkbox-selectAll2"
                          className="checkBoxTxt masterChecked"
                        >
                          JOBR Coin
                        </label> */}
                      </div>
                      <div className="darkBlueCheck me-3">
                        <input
                          type="checkbox"
                          id="custom-checkbox-selectAll3"
                          className="checkBox "
                          name="custom-checkbox-selectAll"
                          value="custom-checkbox-selectAll"
                          checked={checked.Cash}
                          onChange={(e) => {
                            handelDataSetChange(e, "Cash", 1, "#6B84D3");
                            setChecked({...checked,Cash: checked.Cash===true ? false: true})
                          }}
                        />
                        <label
                          htmlFor="custom-checkbox-selectAll3"
                          className="checkBoxTxt masterChecked"
                        >
                          Cash
                        </label>
                      </div>
                      <div className="lightBlueCheck me-3">
                        <input
                          type="checkbox"
                          id="custom-checkbox-selectAll4"
                          className="checkBox "
                          name="custom-checkbox-selectAll"
                          value="custom-checkbox-selectAll"
                          checked={checked.Card}
                          onChange={(e) => {
                            handelDataSetChange(e, "Card", 2, "#102773");
                            setChecked({...checked,Card: checked.Card===true ? false: true})
                          }}
                        />
                        <label
                          htmlFor="custom-checkbox-selectAll4"
                          className="checkBoxTxt masterChecked"
                        >
                          Card
                        </label>
                      </div>
                    </div>
                    {/* <div className='processStatus mt-3'>
                        <p className='statusbox_'><span className='complete_'></span> Cash In</p>
                        <p className='statusbox_'> <span className='cancel_'></span> Cash Out</p>
                      </div> */}
                    <div className="row mt-4 mb-4 align-items-center">
                      <div className="col-md-12">
                        {/* {/ <img src={walletGraph} alt="Graph Image" className='img-fluid' /> /} */}
                        <ChartArea
                          classes="productSales mt-2 mb-2"
                          header="Services Sales"
                          push={() => history.push('/transections')}
                          options={options1}
                          data={{ labels: orderWalletTransactions?.graphData?.labels, datasets: dataSets }}
                          chartType="Bar"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default WalletMain;
